import React from "react";
import PropTypes from 'prop-types';
import { Family, Suspect, Vector } from "./Pins";

const Map = ({data, pinType, isLoading}) => {
  function cargarMapa(data, pinType) {
    const map = L.map("map", {
      center: [-28.776841, -57.920494], //Ubica el mapa en corrientes capital

      zoom: 8,

      MinZoom: 0,

      MaxZoom: 40,

      zoomControl: true,
    });

    let capaOSM = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    const markerCluster = new L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      zoomToBoundsOnClick: true,
      removeOutsideVisibleBounds: true,
      chunkedLoading: true,
      maxClusterRadius: function (zoom) {
        if (zoom > 17) {
          return 20;
        } else if (zoom > 12) {
          return 40;
        } else {
          return 80;
        }
      },
    });
    var greenIcon = new L.Icon({
      iconUrl:
        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
      iconSize: [18, 30],
      iconAnchor: [9, 30],
      popupAnchor: [1, -34],
      shadowSize: [30, 30],
    });

    data.map((value) => {
      if (value.latitud !== null) {
        var location = [value.latitud, value.longitud];

        var marker = L.marker(location, { icon: greenIcon });
        marker.bindPopup(
          pinType === "suspect" ? Suspect(value) :
          pinType === "family" ? Family(value) :
          pinType === "vector" && Vector(value)
        );

        markerCluster.addLayer(marker);
      }
    });
    map.addLayer(markerCluster);
  }

  React.useLayoutEffect(() => {
    if (data && !isLoading) {
      cargarMapa(data, pinType);
    }
  }, [data, isLoading]);

  return (
    <div id="map" style={{ height: "700px" }}/>
  );
};



const propTypes = {
  /**
   * Array de objetos con datos de las personas.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      apellido: PropTypes.string.isRequired,
      barrio: PropTypes.string.isRequired,
      cantidad: PropTypes.number.isRequired,
      codigo_aspersor: PropTypes.oneOfType([PropTypes.string, PropTypes.null]),
      codigo_ovitrampa: PropTypes.string.isRequired,
      dni: PropTypes.string.isRequired,
      domicilio: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      fecha_resultado: PropTypes.oneOfType([PropTypes.string, PropTypes.null]),
      fecha_carga: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      id_localidad: PropTypes.number.isRequired,
      kit: PropTypes.bool.isRequired,
      latitud: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
      localidad: PropTypes.string.isRequired,
      longitud: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
      nombre: PropTypes.string.isRequired,
      ovitrampa: PropTypes.bool.isRequired,
      resultado: PropTypes.oneOfType([PropTypes.string, PropTypes.null]),
      role: PropTypes.number.isRequired,
      telefono: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * Tipo de marcador a mostrar en el mapa.
   * Puede ser "family", "suspect" o "vector".
   */
  pinType: PropTypes.oneOf(['family', 'suspect', 'vector']).isRequired,
  /**
   * Indica si se está cargando la información.
   */
  isLoading: PropTypes.bool.isRequired,
};

Map.prototype = propTypes;

export default Map;
